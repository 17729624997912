import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Hem",
  "priority": 0
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Introduktion`}</h1>
    <p>{`Hjärtligt välkommen till min sida. A work in progress if ever there was one.`}</p>
    <p>{`Välj bland länkarna i menyn för att läsa mer!`}</p>
    <p>{`Oh, this is just temporary!`}</p>
    <p><a parentName="p" {...{
        "href": "https://gregerhalltorp.github.io/set-counter/"
      }}>{`Set Counter`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      